import React from 'react'
import { Layout, Menu } from 'antd'
import { CoffeeOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Sider } = Layout

const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#fff',
    padding: '0 5px'
}

const menuStyle = {
    height: '100%'
}

const items = [
    {
        key: '/',
        icon: <CoffeeOutlined />,
        label: '欢迎'
    },
    {
        key: '/index',
        icon: <HomeOutlined />,
        label: '官网配置',
        children: [
            {
                key: '/index',
                label: '收录信息'
            }
        ]
    },
    {
        key: '/account',
        icon: <UserOutlined />,
        label: '账号管理',
        children: [
            {
                key: '/account/audit',
                label: '待审核账号',
                path: ''
            },
            {
                key: '/account/info',
                label: '账号信息'
            }
        ]
    }
]

const Components = () => {
    const defaultSelectedKeys = window.location.pathname
    const defaultOpenKeys = window.location.pathname.split('/')[1]
    const navigate = useNavigate()

    const onClick = (e) => {
        navigate(e.key)
    }

    return (
        <Sider style={siderStyle}>
            <Menu style={menuStyle} onClick={onClick} defaultSelectedKeys={[defaultSelectedKeys]} defaultOpenKeys={['/' + defaultOpenKeys]} mode="inline" items={items} />
        </Sider>
    )
}

export default Components
