import request from 'api/request'

// 新建首页录入
export const createIndex = (data = {}) => {
    return request('/api/index/create', { method: 'POST', data })
}

// 首页录入列表
export const indexList = (data = {}) => {
    return request('/api/index/list', { method: 'POST', data })
}

// 删除首页录入
export const deleteIndex = (data = {}) => {
    return request('/api/index/delete', { method: 'POST', data })
}

// 保存首页录入
export const saveIndex = (data = {}) => {
    return request('/api/index/save', { method: 'POST', data })
}
