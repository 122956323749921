import React from 'react'
import { Button, Form, Input, Divider, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

import { login } from 'api/admin'

import styles from './index.module.scss'

const Login = () => {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()

    const onFinish = async (value) => {
        const res = await login(value)
        if (res.statusCode === 200) {
            messageApi.success('登录成功')
            navigate('/')
        } else {
            messageApi.error(res?.message || '登录失败')
        }
    }

    return (
        <>
            {contextHolder}
            <div className={styles.view}>
                <img className={styles.logo} src="./logo1.png" />
                <p className={styles.desc}>We want you to have fun every day</p>
                <Divider />
                <Form className={styles.form} onFinish={onFinish}>
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: '请输入您的手机号'
                            }
                        ]}>
                        <Input placeholder="请输入您的手机号" prefix={<UserOutlined />} />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入你的密码'
                            }
                        ]}>
                        <Input.Password placeholder="请输入你的密码" prefix={<LockOutlined />} />
                    </Form.Item>
                    <div className={styles.controls}>
                        <b className={styles.link} onClick={() => navigate('/signin')}>
                            注册
                        </b>
                        <b className={styles.link} onClick={() => navigate('/forget')}>
                            忘记密码
                        </b>
                    </div>
                    <Form.Item>
                        <Button type="primary" className={styles.loginBtn} htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default Login
