import { Upload } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'

const updateAddStyle = {
    color: '#777',
    fontSize: 24
}

const CUpdate = ({ value = [], onChange = () => {}, max = 1 }) => {
    return (
        <Upload
            action="https://muyuxiao.cn/api/updateFie"
            data={{
                path: 'admin'
            }}
            listType="picture-card"
            fileList={value}
            maxCount={max}
            onChange={({ fileList }) =>
                onChange(
                    fileList.map((e) => ({
                        ...e,
                        thumbUrl: e?.response?.data || e?.thumbUrl || ''
                    }))
                )
            }>
            {value.length < max ? <CloudUploadOutlined style={updateAddStyle} /> : null}
        </Upload>
    )
}

export default CUpdate
