import React from 'react'
import { Dropdown } from 'antd'

import Internationalization from 'svg/internationalization.svg'

const internationalizationStyle = {
    width: 24,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    marginRight: 20
}

const Components = () => {
    return (
        <Dropdown
            menu={{
                items: [
                    {
                        key: '1',
                        label: <span>中文</span>
                    }
                ]
            }}
            placement="bottom">
            <div style={internationalizationStyle}>
                <img width={24} height={24} src={Internationalization} />
            </div>
        </Dropdown>
    )
}

export default Components
