import React, { useEffect, useState } from 'react'
import { Layout, Watermark, Card, Spin } from 'antd'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useSetRecoilState } from 'recoil'

import KeepAlive from 'components/KeepAlive'
import Header from './components/Header'
import Sider from './components/Sider'
import { getAdminInfo } from 'api/admin'
import { adminInfoAtom } from 'atoms'

const { Content } = Layout

const LayoutStyle = {
    height: '100%',
    paddingBottom: 10,
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundColor: '#fff'
}

const contentStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#fff',
    padding: '10px 20px',
    overflowY: 'auto'
}

const View = () => {
    const navigate = useNavigate()
    const setAdminInfo = useSetRecoilState(adminInfoAtom)
    const [spinning, setSpinning] = useState(true)

    // 获取用户信息
    const getAdmin = async () => {
        try {
            const res = await getAdminInfo()

            console.log('res', res)

            if (res.statusCode === 200 && res.data) {
                setAdminInfo(res.data)
                setSpinning(false)
            } else {
                // 获取不到用户信息，跳转去登录页面
                navigate('/login?redirectUrl=' + encodeURIComponent(window.location.pathname))
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAdmin()
    }, [])

    return (
        <>
            {!spinning && (
                <Layout style={LayoutStyle}>
                    <Header />
                    <Layout hasSider>
                        <Sider />
                        <Content style={contentStyle}>
                            {/* <Card> */}
                            {/* <Watermark content="MYKJ"> */}
                            <KeepAlive>
                                <Outlet />
                            </KeepAlive>
                            {/* </Watermark> */}
                            {/* </Card> */}
                        </Content>
                    </Layout>
                </Layout>
            )}
            <Spin spinning={spinning} indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
        </>
    )
}

export default View
