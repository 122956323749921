import request from 'api/request'

// 获取用户信息
export const getAdminInfo = () => {
    return request('/api/admin/getInfo', { method: 'GET' })
}

// 登录
export const login = (data = {}) => {
    return request('/api/admin/login', { data })
}

// 注册
export const signin = (data = {}) => {
    return request('/api/admin/signin', { data })
}

// 退出登录
export const logout = () => {
    return request('/api/admin/logout')
}

// 忘记密码-修改密码
export const forget = (data = {}) => {
    return request('/api/admin/forget', { data })
}

// 待审核列表
export const getPendingList = (data = {}) => {
    return request('/api/admin/getPendingList', { method: 'GET', data })
}

// 修改状态
export const setStatus = (data = {}) => {
    return request('/api/admin/setStatus', { data })
}
