import { useEffect, useRef, useState, useCallback } from 'react'
import { useLocation, useOutlet } from 'react-router-dom'

function KeepAlive() {
    const componentList = useRef(new Map())
    const outLet = useOutlet()
    const { pathname } = useLocation()
    const [, setState] = useState({})

    const update = useCallback(() => {
        setState({})
    }, [])

    useEffect(() => {
        if (!componentList.current.has(pathname)) {
            componentList.current.set(pathname, outLet)
        }
        update()
    }, [pathname])

    return (
        <div>
            {Array.from(componentList.current).map(([key, component]) => (
                <div key={key} style={{ display: pathname === key ? 'block' : 'none' }}>
                    {component}
                </div>
            ))}
        </div>
    )
}

export default KeepAlive
