import React from 'react'
import { Dropdown, Avatar, message } from 'antd'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'

import { adminInfoAtom } from 'atoms'
import { logout } from 'api/admin'

const nameStyle = {
    color: '#122c42',
    marginLeft: 12,
    fontWeight: 600,
    fontSize: 15
}

const Components = () => {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const adminInfo = useRecoilValue(adminInfoAtom)

    const handleLogout = async () => {
        try {
            const res = await logout()

            if (res.statusCode === 200) {
                messageApi.success('退出成功')
                setTimeout(() => {
                    navigate('/login')
                }, 1200)
            } else {
                messageApi.error(res?.message || '无法退出')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {contextHolder}
            <Dropdown
                menu={{
                    items: [
                        {
                            key: '1',
                            label: <span onClick={() => handleLogout()}>退出登录</span>
                        }
                    ]
                }}
                placement="bottom">
                <div style={{ cursor: 'pointer' }}>
                    <Avatar size={32} src={adminInfo.avatar} />
                    <span style={nameStyle}>{adminInfo.name}</span>
                </div>
            </Dropdown>
        </>
    )
}

export default Components
