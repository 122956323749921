import React from 'react'
import { Layout } from 'antd'

import Internationalization from './Internationalization'
import UserInfo from './UserInfo'

const { Header } = Layout

const headerStyle = {
    color: '#fff',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)',
    backdropFilter: 'blur(8px)',
    zIndex: 99,
    marginBottom: 10
}

const logoStyle = {
    width: 'auto',
    height: 64
}

const rightStyle = {
    display: 'flex',
    justifyContent: 'end-flex',
    alignItems: 'center'
}

const Components = () => {
    return (
        <Header style={headerStyle}>
            <img style={logoStyle} src="/logo1.png" />
            <div style={rightStyle}>
                <Internationalization />
                <UserInfo />
            </div>
        </Header>
    )
}

export default Components
