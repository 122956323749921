import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN'

import { RecoilRoot } from 'recoil'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <RecoilRoot>
            <ConfigProvider
                locale={zhCN}
                theme={{
                    token: {
                        // colorPrimary: '#333840',
                        // colorPrimary: '#122c42', // 主题色
                        // colorBgBase: '#676767',
                        // colorTextBase: '#122c42',
                        // colorLink: '#122c42'
                    }
                }}>
                <App />
            </ConfigProvider>
        </RecoilRoot>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
