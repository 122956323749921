import React from "react";

import styles from "./index.module.scss";

const Welcome = () => {
    return (
      <div className={styles.view}>
        <h1>Welcome~</h1>
      </div>
    );
}

export default Welcome