import request from 'api/request'

// 发送验证码
export const sendSmsCode = (params = {}) => {
    return request('/api/common/sendSmsCode', { method: 'GET', params })
}

// 获取小栗助手用户下拉选项
export const xiaoliUserOptions = (params = {}) => {
    return request('/api/admin/user/xiaoli/userOptions', { method: 'GET', params })
}
